import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
    timeout: 0,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, HEAD, POST, PUT, DELETE, OPTIONS",
        "Authorization": `Bearer ${localStorage.getItem('Authorization')}`
    },
    credentials: 'include',
});

$api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response.data.statusCode === 401) {
            if (localStorage.getItem('Authorization')) {
                localStorage.removeItem('Authorization');
                window.location.href = '/login?expired=true';
            }
            return await Promise.reject(error);
        } else {
            return error.response;
        }
    }
);

export default $api;
