// Store
import { makeAutoObservable } from "mobx";

// Stores
import AuthStore from './auth.store';
import ProfileStore from "./profile.store";
import StudentsStore from './students.store';
import CheckInStore from './check-in.store';
import ConnectionStore from './connection.store';
import CircleStore from './circle.store';
import InsightsStore from './insights.store';

export default class Store {
    authStore: AuthStore;
    studentsStore: StudentsStore;
    profileStore: ProfileStore;
    checkInStore: CheckInStore;
    connectionStore: ConnectionStore;
    circleStore: CircleStore;
    insightsStore: InsightsStore;

    constructor() {
        this.authStore = new AuthStore(this);
        this.studentsStore = new StudentsStore(this);
        this.profileStore = new ProfileStore(this);
        this.checkInStore = new CheckInStore(this);
        this.connectionStore = new ConnectionStore(this);
        this.circleStore = new CircleStore(this);
        this.insightsStore = new InsightsStore(this);

        makeAutoObservable(this);
    }


}