// Core
import { makeAutoObservable } from "mobx";
import { AxiosResponse } from 'axios';

// Store
import Store from './store';

// Services
import CircleService from '../services/circle.service';

// Enums
import { ActivityGradeEnum } from '../common/enums';

export default class CircleStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  async getActivity(grade: ActivityGradeEnum): Promise<AxiosResponse> {
    try {
      return await CircleService.getActivity(grade)
    } catch (error: any) {
      return error;
    }
  }

  async createCircle(data: {
    comment: number
    activity_id: number
  }): Promise<AxiosResponse> {
    try {
      return await CircleService.createCircle(data)
    } catch (error: any) {
      return error;
    }
  }

}