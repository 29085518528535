// Http
import $api from '../common/http';

// Core
import { AxiosResponse } from 'axios';

// Types
import { EventToCreateType } from '../models/types';

export default class CheckInService {
  static async isFirstCheckIn(): Promise<{ data: boolean }> {
    try {
      return await $api.get('/check-in/first');
    }catch (error: any) {
      return error;
    }
  }

  static async getEventsStatistics(): Promise<AxiosResponse> {
    try {
      return await $api.get('/check-in/events-statistics');
    }catch (error: any) {
      return error;
    }
  }

  static async getDaysStatistics(): Promise<AxiosResponse> {
    try {
      return await $api.get('/check-in/days-statistics');
    }catch (error: any) {
      return error;
    }
  }

  static async getStudentsByClassId(class_id: number): Promise<AxiosResponse> {
    try {
      return await $api.get(`/check-in/student-list/${class_id}`);
    }catch (error: any) {
      return error;
    }
  }

  static async createCheckIn(class_id: number, date: Date): Promise<AxiosResponse> {
    try {
      return await $api.post(`/check-in/create/${class_id}`, {
        date
      });
    }catch (error: any) {
      return error;
    }
  }

  static async createEvents(data: EventToCreateType[]): Promise<AxiosResponse> {
    try {
      return await $api.post('/check-in/event/create', data);
    }catch (error: any) {
      return error;
    }
  }

  static async getAllBehaviors(): Promise<AxiosResponse> {
    try {
      return await $api.get('/check-in/behaviors');
    }catch (error: any) {
      return error;
    }
  }
}