// Core
import { makeAutoObservable } from "mobx";
import { AxiosResponse } from 'axios';

// Store
import Store from './store';

// Services
import CheckInService from '../services/check-in.service';

// Types
import { EventToCreateType } from '../models/types';

export default class CheckInStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  isCheckIn = false;

  setIsCheckIn = (bool: boolean) => (this.isCheckIn = bool);

  async isFirstCheckIn(): Promise<{ data: boolean }> {
    try {
      return await CheckInService.isFirstCheckIn()
    }catch (error: any) {
      return error;
    }
  }

  async getEventsStatistics(): Promise<AxiosResponse> {
    try {
      return await CheckInService.getEventsStatistics()
    }catch (error: any) {
      return error;
    }
  }

  async getDaysStatistics(): Promise<AxiosResponse> {
    try {
      return await CheckInService.getDaysStatistics()
    }catch (error: any) {
      return error;
    }
  }

  async getStudentsByClassId(class_id: number): Promise<AxiosResponse> {
    try {
      return await CheckInService.getStudentsByClassId(class_id)
    }catch (error: any) {
      return error;
    }
  }

  async createCheckIn(class_id: number, date: Date): Promise<AxiosResponse> {
    try {
      return await CheckInService.createCheckIn(class_id, date)
    }catch (error: any) {
      return error;
    }
  }

  async createEvents(data: EventToCreateType[]): Promise<AxiosResponse> {
    try {
      return await CheckInService.createEvents(data)
    }catch (error: any) {
      return error;
    }
  }

  async getAllBehaviors(): Promise<AxiosResponse> {
    try {
      return await CheckInService.getAllBehaviors()
    }catch (error: any) {
      return error;
    }
  }
}