// Core
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import './assets/fonts/fonts.css';

// Root
import Root from './screens/Root/root';

// Other
import theme from './common/theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router basename={process.env.PUBLIC_URL || '/'}>
        <CssBaseline />
        <Root />
      </Router>
    </ThemeProvider>
  );
};

export default observer(App);
