import * as React from "react";

interface AuthenticatedLayoutProps {
    children: React.ReactElement | React.ReactElement[];
}

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
    children
}: AuthenticatedLayoutProps) => {
    return <div>{children}</div>;
};

export default AuthenticatedLayout;
