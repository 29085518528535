// Core
import { makeAutoObservable } from "mobx";


// Store
import Store from './store';

// Services
import StudentsService from '../services/students.service';
import { ExtendedStudentsListType, StudentsQuery } from '../models/types';
import { AxiosResponse } from 'axios';


export default class AuthStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  async getStudents(query: StudentsQuery): Promise<{
    data: ExtendedStudentsListType
  }> {
    try {
      return await StudentsService.getStudents(query);
    } catch (e) {
      throw e;
    }
  }

  async getStudent(id: number): Promise<AxiosResponse> {
    try {
      return await StudentsService.getStudent(id);
    } catch (e: any) {
      return e;
    }
  }
}