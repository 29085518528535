// Core
import React, { FC, useContext, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

// Components
import LoadingComponent from '../loading-component/loading-component';

// Classes
import './terms-of-service-popup.scss';

// Context
import { Context } from '../../index';

// Images
import logo from '../../assets/img/main_logo_extended.svg';

const TermsOfServicePopup: FC<{ setTermsAgree: (v: boolean) => void }> = ({ setTermsAgree }) => {

  const [termsAgreeScreen, setTermsAgreeScreen] = useState<boolean>(false);
  const [termsData, setTerms] = useState<{id: number, terms: any, date_created: Date}>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    store: {
      profileStore: { acceptTerms, getTerms }
    }
  } = useContext(Context);

  useEffect(() => {
    getTermsHandler().then();
  }, []);

  const getTermsHandler = async () => {
    try {
      setLoading(true)
      const response = await getTerms();
      setTerms(response.data)
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false)
    }
  }

  const acceptTermsHandler = () => {
    try {
      setLoading(true)
      acceptTerms().then(() => {
        setTermsAgree(true);
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false)
    }
  };

  if (isLoading) return (<LoadingComponent />)

  return (
    <div className="terms-content">
      {
        !termsAgreeScreen ?
          <div className="terms-content-container">
            <img src={logo} alt="Logo" />
            <h3>Welcome to</h3>
            <h1>Highfive Classroom</h1>
            <p>Before you can continue, please read and accept our <span onClick={() => setTermsAgreeScreen(true)}> <br/> Terms of Service</span>.
            </p>
            <button onClick={acceptTermsHandler}>
              Accept
            </button>
          </div>
          :
          <div className="terms-content-container">
            <div className="terms-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termsData?.terms) }} />
            <button onClick={() => setTermsAgreeScreen(false)} className="back">Back</button>
          </div>
      }
    </div>
  );
};

export default TermsOfServicePopup;