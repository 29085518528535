// Core
import React, { FC, Suspense, lazy, useEffect, useState, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// Components
import AuthenticatedLayout from '../../components/authenticated-layout/authenticated-layout';
import TermsOfServicePopup from '../../components/terms-of-service-popup/terms-of-service-popup';

// Other
import { Context } from '../../index';

// Types
import ThirdLogin from '../../pages/third-login/third-login';

// Lazy
const Login = lazy(() => import('../../pages/login/login'));
const MainPage = lazy(() => import('../../pages/main/main'));
const StudentsPage = lazy(() => import('../../pages/students/students'));
const StudentPage = lazy(() => import('../../pages/student/student'));
const StudentInsightsPage = lazy(() => import('../../pages/student/insights/insights'));
const InsightsPage = lazy(() => import('../../pages/insights/insights'));
const InsightsDetailsPage = lazy(() => import('../../pages/insights-details/insights-details'));
const AccountPage = lazy(() => import('../../pages/account/account'));
const ResetPasswordPage = lazy(() => import('../../pages/reset-password/reset-password'));
const NewEmailSuccessPage = lazy(() => import('../../pages/new-email/new-email'));

const Root: FC = () => {
  const {
    store: {
      authStore: { checkAuth, isAuth, setAuth, setAccount, account }
    }
  } = useContext(Context);

  const [isTermsAgree, setTermsAgree] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const token = localStorage.getItem('Authorization')

  useEffect(() => {
    const authenticateUser = async () => {
      setLoading(true);
      const user = await checkAuth();
      if (user) {
        setAccount(user);
        setAuth(true);
        setTermsAgree(user.agreed_to_terms);
      } else {
        setAuth(false);
      }
      setLoading(false);
    };

    if (token) {
      authenticateUser().then();
    } else {
      setAuth(false);
      setLoading(false);
    }
  }, [token, checkAuth, setAccount, setAuth, setTermsAgree]);

  if (!isTermsAgree) {
    return (
      <TermsOfServicePopup setTermsAgree={setTermsAgree} />
    );
  }

  return (
    <AuthenticatedLayout>
      <Suspense fallback={<div />}>
        {!loading && (
          <Routes>
            <Route
              path="/"
              element={isAuth ? <MainPage account={account!} /> : <Navigate to="/login" />}
            />
            <Route
              path="/students"
              element={isAuth ? <StudentsPage account={account!} /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/:id"
              element={isAuth ? <StudentPage account={account!} /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/:id/insights/:type"
              element={isAuth ? <StudentInsightsPage account={account!} /> : <Navigate to="/login" />}
            />
            <Route
              path="/insights"
              element={isAuth ? <InsightsPage account={account!} /> : <Navigate to="/login" />}
            />
            <Route path="/insights/:type"
                   element={isAuth ? <InsightsDetailsPage account={account!} /> : <Navigate to="/login" />} />
            <Route path="/insights/:type/:class_id"
                   element={isAuth ? <InsightsDetailsPage account={account!} /> : <Navigate to="/login" />} />
            <Route
              path="/profile"
              element={isAuth ? <AccountPage account={account!} /> : <Navigate to="/login" />}
            />
            <Route
              path="/new-email"
              element={<NewEmailSuccessPage account={account!} />}
            />
            <Route
              path="/login"
              element={isAuth ? <Navigate to="/" /> : <Login />}
            />
            <Route path="/login/:service" element={isAuth ? <Navigate to="/" /> : <ThirdLogin />} />
            <Route
              path="/reset-password"
              element={isAuth ? <Navigate to="/" /> : <ResetPasswordPage />}
            />
          </Routes>
        )}
      </Suspense>
    </AuthenticatedLayout>
  );
};

export default observer(Root);
