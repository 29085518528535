// Core
import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';

// Store
import Store from './store';

// Services
import AuthService from '../services/auth.service';
import SupportService from '../services/support.service';

// Models
import { AccountTeacher } from '../models/types';

export default class AuthStore {
  rootStore: Store;

  isAuth = false;
  account = {} as AccountTeacher;

  constructor(rootStore: Store) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  setAuth = (bool: boolean) => (this.isAuth = bool);

  setAccount = (account: AccountTeacher) => (this.account = account);

  async checkAuth(): Promise<AccountTeacher | void> {
    try {
      const response = await AuthService.checkAuth();
      if (response) {
        return response.data;
      }
    } catch (e) {
      throw e;
    }
  }

  async login(loginData: {
    email: string;
    password: string;
    rememberMe: boolean;
  }): Promise<AxiosResponse> {
    return await AuthService.login(loginData);
  }

  async thirdPartyLogin(code: string, service: string): Promise<AxiosResponse> {
    try {
      return await AuthService.thirdPartyLogin(code, service);
    } catch (e) {
      throw e;
    }
  }

  async logout(): Promise<void> {
    try {
      await AuthService.logout();
    } catch (e) {
      throw e;
    }
  }

  async resetPassword(email: string): Promise<AxiosResponse> {
    try {
      return await AuthService.resetPassword(email);
    } catch (e) {
      throw e;
    }
  }

  async varifyPage(key: string, token: string): Promise<{ data: boolean }> {
    try {
      return await AuthService.varifyPage(key, token);
    } catch (e) {
      throw e;
    }
  }

  async changePassword(data: { password: string; key: string; token: string }): Promise<AxiosResponse> {
    try {
      return await AuthService.changePassword(data);
    } catch (e) {
      throw e;
    }
  }

  async requestSupport(data: { message: string, type: number }): Promise<AxiosResponse> {
    try {
      return await SupportService.requestSupport(data);
    } catch (e) {
      throw e;
    }
  }

}
