export enum StudentsSortOptionsEnum {
  // FIRST_NAME = 'first_name',
  // LAST_NAME = 'last_name',
  // GRADE = 'grade',
  // CONSEQUENCE = 'consequence',
  // SUPPORT = 'support',
  // HIGHFIVE = 'highfive',
  FIRST_NAME_ASC =  'First Name, A to Z',
  FIRST_NAME_DESC = 'First Name, Z to A',
  LAST_NAME_ASC = 'Last Name, A to Z',
  LAST_NAME_DESC = 'Last Name, Z to A',
  GRADE_ASC = 'Grade, Lowest to Highest',
  GRADE_DESC = 'Grade, Highest to Lowest',
}