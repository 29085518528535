// Http
import $api from '../common/http';

// Models
import { AccountTeacher } from '../models/types';
import { AxiosResponse } from 'axios';

export default class AuthService {

  static async login(loginData: {
    email: string;
    password: string;
    rememberMe: boolean;
  }): Promise<AxiosResponse> {
    try {
      return await $api.post('/auth/login', loginData);
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  static async thirdPartyLogin(code: string, service: string): Promise<AxiosResponse> {
    try {
      return await $api.get(`/auth/login/${service}/teacher?code=${code}`);
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  static async checkAuth(): Promise<{ data: AccountTeacher }> {
    try {
      return await $api.get('/auth/me');
    } catch (e: any) {
      return e;
    }
  }

  static async logout() {
    try {
      localStorage.removeItem('Authorization')
      return await $api.post('/auth/logout');
    } catch (e: any) {
      throw e;
    }
  }

  static async resetPassword(email: string): Promise<AxiosResponse> {
    try {
      return await $api.post('/notification/reset-password', { email });
    } catch (e: any) {
      throw e;
    }
  }

  static async varifyPage(key: string, token: string): Promise<{ data: boolean }> {
    try {
      return await $api.get(`/notification/health-check/reset?key=${key}&token=${token}`);
    } catch (e: any) {
      throw e;
    }
  }

  static async changePassword(data: { password: string; key: string; token: string }): Promise<AxiosResponse> {
    try {
      return await $api.post('/auth/change-password', data);
    } catch (e: any) {
      throw e;
    }
  }
}
