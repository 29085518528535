import $api from '../common/http';
import { TeacherTitleEnum } from '../common/enums';
import { GetProfileLeaderboard, Teacher, TicketType } from '../models/types';
import { AxiosResponse } from 'axios';

export class ProfileService {
  static async getProfileLeaderboard(limit?: number): Promise<{ data: GetProfileLeaderboard }> {
    try {
      return await $api.get(`/profile/leaderboard?limit=${limit}`);
    }catch (error: any) {
      return error;
    }
  }

  static async acceptTerms(): Promise<void> {
    try {
      return await $api.post('/profile/sign/terms');
    } catch (e: any) {
      throw e;
    }
  }

  static async getTerms(): Promise<{data: {id: number, terms: any, date_created: Date}}> {
    try {
      return await $api.get('/profile/terms');
    } catch (e: any) {
      throw e;
    }
  }

  static async updateTeacher(data: {
    first_name?: string,
    middle_name?: string,
    last_name?: string,
    title_id?: TeacherTitleEnum
  }): Promise<{data: Teacher}> {
    try {
      return await $api.patch('/profile/teacher', data);
    } catch (e: any) {
      throw e;
    }
  }

  static async updatePassword(data: {
    password: string,
  }): Promise<void> {
    try {
      return await $api.patch('/profile/change-password', data);
    } catch (e: any) {
      throw e;
    }
  }

  static async updateImage(data: {
    account_img: string,
  }): Promise<void> {
    try {
      return await $api.patch('/profile/update-image', data);
    } catch (e: any) {
      throw e;
    }
  }

  static async updateEmailRequest(data: {
    email_address: string,
    isMobile: boolean
  }): Promise<AxiosResponse> {
    try {
      return await $api.patch('/profile/update-email-request', data);
    } catch (e: any) {
      throw e;
    }
  }

  static async updateEmail(key: string, token: string, email: string): Promise<{data: boolean}> {
    try {
      return await $api.get(`/profile/update-email?key=${key}&token=${token}&email=${email}`);
    } catch (e: any) {
      throw e;
    }
  }

  static async uploadAvatar(formData: FormData): Promise<{ data: string }> {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        withCredentials: true
      };
      return await $api.post(`/profile/upload/avatar`, formData, config);
    } catch (e: any) {
      throw e;
    }
  }

  static async getTicketTypes(): Promise<{data: TicketType[]}> {
    try {
      return await $api.get('/profile/ticket-types');
    } catch (e: any) {
      throw e;
    }
  }
}